<template>
  <div class="rightContent">

    <div v-loading="topLoading" class="top">
      <div class="title center">热点要闻</div>
      <div v-if="topData.length">
        <div v-for="item in topData" :key="item.id" class="item center click" @click="$go(`/main/detail?id=${item.id}`)">
          <div class="circle" />
          <div class="text oneLine">{{ item.title }}</div>
        </div>
      </div>
      <div v-else style="flex: 1; font-size: 100px;height:500px" class="colCenter it">
        <img class="emptyImg" :src="require('@/assets/home/first/empty.png')">
        <span>暂无内容</span>
      </div>
    </div>

    <div v-loading="bottomLoading" class="bottom">
      <div class="title center">党建视频</div>
      <div v-if="bottomData.length">
        <div v-for="item in bottomData" :key="item.id" class="item click" @click="$go(`/main/detail?id=${item.id}`)">
          <div class="imgOut">
            <img class="videoImg" :src="$beforeUrl(item.videoPoster)">
            <img class="videoIcon" :src="require('@/assets/home/first/1/12.png')">
          </div>
          <div class="text oneLine">{{ item.title }}</div>
        </div>
      </div>
      <div v-else style="flex: 1; font-size: 100px;height:500px" class="colCenter it">
        <img class="emptyImg" :src="require('@/assets/home/first/empty.png')">
        <span>暂无内容</span>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      topData: [

      ],
      topLoading: true,

      bottomData: [
      ],
      bottomLoading: true
    }
  },

  mounted() {

  },
  created() {
    this.getHotData()
    this.getVedioData()
  },

  methods: {
    getHotData() {
      this.topLoading = true
      this.$store.dispatch('main/HomeGetHotNews', { top: 6 }).then(res => {
        if (res.code === 0) {
          this.topData = res.data
          this.topLoading = false
        }
      })
    },
    getVedioData() {
      this.bottomLoading = true
      this.$store.dispatch('main/HomeGetVideoNews', { top: 3 }).then(res => {
        if (res.code === 0) {
          this.bottomData = res.data
          this.bottomLoading = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .rightContent{
    .top{
      border: 1px solid #EEEEEE;
      padding-bottom: 14.5px;
      .title{
        border-bottom: 1px solid #EEEEEE;
        height: 63px;
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin-bottom: 14.5px;
      }
      .item{
        padding: 14.5px 26px 14.5px 21px;
        .circle{
          width: 4px;
          height: 4px;
          background: #999999;
          border-radius: 50%;
          margin-right: 7px;
        }
        .text{
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          flex: 1;
        }
      }
    }
    .bottom{
      border: 1px solid #EEEEEE;
      padding-bottom: 10px;
      margin-top: 24px;
      .title{
        border-bottom: 1px solid #EEEEEE;
        height: 63px;
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin-bottom: 10px;
      }
      .item{
        padding: 10px 26px 10px 21px;
        .imgOut{
          position: relative;
          .videoImg{
            width: 261px;
            height: 173px;
          }
          .videoIcon{
            width: 46px;
            height: 46px;
            position: absolute;
            left: 20px;
            bottom: 14px;
          }
        }
        .text{
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          flex: 1;
          margin-top: 9px;
        }
      }
    }
  }
  .emptyImg{
    width: 180px;
    height: 150px;
  }
</style>

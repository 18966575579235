<template>
  <div v-loading="loading" class="main">
    <router-view :loading="loading" style="flex:1" />
    <right class="right" />
    <el-backtop />
  </div>
</template>

<script>
import right from './components/right'
export default {
  components: {
    right
  },

  data() {
    return {
      loading: true
    }
  },
  created() {
    window.scrollTo(0, 0)
  }

}
</script>

<style lang="scss" scoped>
  .main{
    padding-top: 24px;
    padding-bottom:97px;
    display: flex;
    width: 1300px;
    margin: 0 auto;

    .right{
      width: 309px;
    }
  }
</style>
